import React from 'react';
import { PlusCircle } from 'lucide-react';

interface ParticipantFormProps {
  name: string;
  setName: (name: string) => void;
  onAdd: () => void;
  onBulkAdd: (names: string[]) => void;
  t: (key: string) => string;
}

export function ParticipantForm({ name, setName, onAdd, onBulkAdd, t }: ParticipantFormProps) {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (name.trim()) {
      // Virgülle ayrılmış isimleri kontrol et
      if (name.includes(',')) {
        const names = name.split(',')
          .map(n => n.trim())
          .filter(n => n.length > 0);
        onBulkAdd(names);
      } else {
        onAdd();
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-3">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={t('participantForm.placeholder')}
          className="flex-1 px-4 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-primary/20 focus:border-primary transition-all text-base"
        />
        <button
          type="submit"
          className="flex items-center justify-center gap-2 px-6 py-3 bg-primary text-white rounded-xl hover:bg-primary-dark transition-colors border-2 border-primary group whitespace-nowrap"
        >
          <PlusCircle size={20} className="group-hover:rotate-180 transition-transform duration-500" />
          {t('participantForm.addButton')}
        </button>
      </form>
      <p className="text-sm text-gray-500 mt-2">
        {t('participantForm.bulkAddHint')}
      </p>
    </div>
  );
}