import { useCallback } from 'react';
import { Language, translations } from '../i18n/translations';

export function useTranslation(language: Language) {
  const t = useCallback(
    (key: string) => {
      const translation = translations[language];
      const keys = key.split('.');
      let value: any = translation;

      for (const k of keys) {
        if (value && typeof value === 'object' && k in value) {
          value = value[k];
        } else {
          console.warn(`Translation key not found: ${key}`);
          return key;
        }
      }

      return value;
    },
    [language]
  );

  return { t };
}