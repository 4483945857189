import React from 'react';
import { RuleType, RULES } from '../types/rules';

interface RuleSelectorProps {
  selectedRule: RuleType;
  onRuleSelect: (rule: RuleType) => void;
  t: (key: string) => string;
}

export function RuleSelector({ selectedRule, onRuleSelect, t }: RuleSelectorProps) {
  return (
    <div className="space-y-4">
      <div className="text-center mb-6">
        <h2 className="text-xl font-semibold mb-2">{t('rules.title')}</h2>
        <p className="text-gray-600">{t('rules.description')}</p>
      </div>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {RULES.map((rule) => (
          <button
            key={rule.type}
            onClick={() => onRuleSelect(rule.type)}
            className={`p-4 rounded-xl border-2 transition-all ${
              selectedRule === rule.type
                ? 'border-primary bg-primary/5 shadow-lg scale-[1.02]'
                : 'border-gray-200 hover:border-primary/50 hover:bg-gray-50'
            }`}
          >
            <div className="flex flex-col items-center text-center gap-2">
              <span className="text-2xl mb-1">{rule.icon}</span>
              <h3 className="font-medium">{t(`rules.${rule.type}.name`)}</h3>
              <p className="text-sm text-gray-600">{t(`rules.${rule.type}.description`)}</p>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
} 