import React, { useState } from 'react';
import { Users, X } from 'lucide-react';
import { Couple } from '../types/rules';

interface CouplesEditorProps {
  couples: Couple[];
  participants: string[];
  onCouplesChange: (couples: Couple[]) => void;
  t: (key: string) => string;
}

export function CouplesEditor({
  couples,
  participants,
  onCouplesChange,
  t,
}: CouplesEditorProps) {
  const [person1, setPerson1] = useState('');
  const [person2, setPerson2] = useState('');

  const availableParticipants = participants.filter(
    (p) =>
      !couples.some((c) => c.person1 === p || c.person2 === p) ||
      p === person1 ||
      p === person2
  );

  const handleAddCouple = (e: React.FormEvent) => {
    e.preventDefault();
    if (person1 && person2 && person1 !== person2) {
      onCouplesChange([...couples, { person1, person2 }]);
      setPerson1('');
      setPerson2('');
    }
  };

  const handleRemoveCouple = (index: number) => {
    const newCouples = [...couples];
    newCouples.splice(index, 1);
    onCouplesChange(newCouples);
  };

  return (
    <div>
      <div className="flex items-center gap-2 mb-3">
        <Users size={24} className="text-primary" />
        <h2 className="text-xl font-semibold">{t('couples.title')}</h2>
      </div>
      
      <p className="text-gray-600 mb-6">{t('couples.description')}</p>

      <form onSubmit={handleAddCouple} className="flex flex-col sm:flex-row gap-3 mb-6">
        <select
          value={person1}
          onChange={(e) => setPerson1(e.target.value)}
          className="flex-1 px-4 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-primary/20 focus:border-primary transition-all text-base"
        >
          <option value="">{t('couples.selectFirst')}</option>
          {availableParticipants.map((p) => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
        </select>
        <select
          value={person2}
          onChange={(e) => setPerson2(e.target.value)}
          className="flex-1 px-4 py-3 border-2 border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-primary/20 focus:border-primary transition-all text-base"
        >
          <option value="">{t('couples.selectSecond')}</option>
          {availableParticipants
            .filter((p) => p !== person1)
            .map((p) => (
              <option key={p} value={p}>
                {p}
              </option>
            ))}
        </select>
        <button
          type="submit"
          disabled={!person1 || !person2}
          className="px-6 py-3 bg-primary text-white rounded-xl hover:bg-primary-dark transition-colors font-medium border-2 border-primary disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
        >
          {t('couples.addButton')}
        </button>
      </form>

      {couples.length > 0 ? (
        <div className="space-y-2">
          {couples.map((couple, index) => (
            <div
              key={index}
              className="flex items-center justify-between p-4 bg-primary/5 rounded-xl border-2 border-primary/10 group hover:border-primary/20 transition-colors"
            >
              <div className="flex items-center gap-3">
                <Users size={20} className="text-primary" />
                <span className="font-medium">{couple.person1}</span>
                <span className="text-primary">💑</span>
                <span className="font-medium">{couple.person2}</span>
              </div>
              <button
                onClick={() => handleRemoveCouple(index)}
                className="text-gray-400 hover:text-primary transition-colors"
              >
                <X size={20} />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-500 p-8 bg-gray-50 rounded-xl border-2 border-gray-100">
          {t('couples.noCouples')}
        </div>
      )}
    </div>
  );
}