import React from 'react';
import { X } from 'lucide-react';

interface ParticipantListProps {
  participants: string[];
  onRemove: (index: number) => void;
  t: (key: string) => string;
}

export function ParticipantList({ participants, onRemove, t }: ParticipantListProps) {
  if (participants.length === 0) {
    return (
      <div className="text-center text-gray-500 mt-4">
        {t('participantForm.noParticipants')}
      </div>
    );
  }

  return (
    <div className="mt-6">
      <h2 className="text-xl font-semibold mb-3">{t('participantForm.participantsTitle')}</h2>
      <div className="space-y-2">
        {participants.map((participant, index) => (
          <div
            key={index}
            className="flex items-center justify-between p-3 bg-white rounded-lg border border-gray-200"
          >
            <span>{participant}</span>
            <button
              onClick={() => onRemove(index)}
              className="text-gray-400 hover:text-primary transition-colors"
            >
              <X size={20} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}