import { RuleType, Couple } from '../types/rules';

function shuffle<T>(array: T[]): T[] {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

function isValidMatch(giver: string, receiver: string, couples: Couple[]): boolean {
  // No one can get themselves
  if (giver === receiver) return false;
  
  // Couples can't get each other
  return !couples.some(
    couple =>
      (couple.person1 === giver && couple.person2 === receiver) ||
      (couple.person2 === giver && couple.person1 === receiver)
  );
}

function generateValidMatches(participants: string[], couples: Couple[]): string[] {
  const maxAttempts = 100;
  let attempts = 0;

  while (attempts < maxAttempts) {
    const shuffled = shuffle(participants);
    let isValid = true;

    // Check if any participant got themselves or their partner
    for (let i = 0; i < shuffled.length; i++) {
      const giver = participants[i];
      const receiver = shuffled[i];
      
      if (!isValidMatch(giver, receiver, couples)) {
        isValid = false;
        break;
      }
    }

    if (isValid) {
      return shuffled;
    }

    attempts++;
  }

  throw new Error('Could not find valid matches after maximum attempts');
}

export function shuffleParticipants(
  participants: string[],
  ruleType: RuleType,
  couples: Couple[]
): [string, string][] {
  if (participants.length < 3) {
    throw new Error('At least 3 participants are required');
  }

  switch (ruleType) {
    case 'normal': {
      const receivers = generateValidMatches(participants, []);
      return participants.map((giver, index) => [giver, receivers[index]]);
    }

    case 'couples': {
      const receivers = generateValidMatches(participants, couples);
      return participants.map((giver, index) => [giver, receivers[index]]);
    }

    case 'chain': {
      const shuffled = shuffle(participants);
      return shuffled.map((participant, index) => [
        participant,
        shuffled[(index + 1) % shuffled.length]
      ]);
    }

    case 'groups': {
      // Split into two groups and match between groups
      const shuffled = shuffle(participants);
      const midPoint = Math.ceil(shuffled.length / 2);
      const group1 = shuffled.slice(0, midPoint);
      const group2 = shuffle(shuffled.slice(midPoint));
      
      return group1.map((giver, index) => [
        giver,
        group2[index % group2.length]
      ]);
    }

    case 'mutual': {
      if (participants.length % 2 !== 0) {
        throw new Error('Mutual exchange requires an even number of participants');
      }

      const shuffled = shuffle(participants);
      const matches: [string, string][] = [];
      
      for (let i = 0; i < shuffled.length; i += 2) {
        const person1 = shuffled[i];
        const person2 = shuffled[i + 1];
        
        // Each person gifts to their partner
        matches.push([person1, person2]);
        matches.push([person2, person1]);
      }
      
      return matches;
    }

    default:
      throw new Error('Invalid rule type');
  }
}