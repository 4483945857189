import React, { useState } from 'react';
import { Coffee, LinkedinIcon, Bot } from 'lucide-react';
import { ParticipantForm } from './components/ParticipantForm';
import { ParticipantList } from './components/ParticipantList';
import { Results } from './components/Results';
import { LanguageSwitch } from './components/LanguageSwitch';
import { ComingSoonModal } from './components/ComingSoonModal';
import { RuleSelector } from './components/RuleSelector';
import { CouplesEditor } from './components/CouplesEditor';
import { shuffleParticipants } from './utils/giftExchange';
import { RuleType, Couple } from './types/rules';
import { Language } from './i18n/translations';
import { useTranslation } from './hooks/useTranslation';

function App() {
  const [language, setLanguage] = useState<Language>('tr');
  const { t } = useTranslation(language);
  const [name, setName] = useState('');
  const [participants, setParticipants] = useState<string[]>([]);
  const [matches, setMatches] = useState<[string, string][]>([]);
  const [isDrawComplete, setIsDrawComplete] = useState(false);
  const [selectedRule, setSelectedRule] = useState<RuleType>('normal');
  const [isComingSoonModalOpen, setIsComingSoonModalOpen] = useState(false);
  const [couples, setCouples] = useState<Couple[]>([]);

  const handleAddParticipant = () => {
    if (name.trim() && !participants.includes(name.trim())) {
      setParticipants([...participants, name.trim()]);
      setName('');
      setIsDrawComplete(false);
      setMatches([]);
    }
  };

  const handleBulkAddParticipants = (names: string[]) => {
    const uniqueNames = names.filter(name => !participants.includes(name));
    if (uniqueNames.length > 0) {
      setParticipants([...participants, ...uniqueNames]);
      setName('');
      setIsDrawComplete(false);
      setMatches([]);
    }
  };

  const handleRemoveParticipant = (index: number) => {
    setCouples(couples.filter(couple => 
      couple.person1 !== participants[index] && couple.person2 !== participants[index]
    ));
    
    setParticipants(participants.filter((_, i) => i !== index));
    setIsDrawComplete(false);
    setMatches([]);
  };

  const handleDraw = () => {
    if (participants.length >= 3) {
      const results = shuffleParticipants(participants, selectedRule, couples);
      setMatches(results);
      setIsDrawComplete(true);
    }
  };

  const handleRuleSelect = (rule: RuleType) => {
    setSelectedRule(rule);
    if (rule !== 'couples') {
      setCouples([]);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 relative flex flex-col">
      <LanguageSwitch currentLanguage={language} onLanguageChange={setLanguage} />
      
      <main className="container mx-auto px-4 py-6 md:py-8 flex-grow">
        <div className="text-center mb-8 md:mb-12 animate-fade-in">
          <div className="flex justify-center mb-4 md:mb-6 gap-1">
            <span className="text-4xl md:text-5xl animate-bounce-subtle">🎅🏻</span>
            <span className="text-4xl md:text-5xl animate-bounce-subtle" style={{ animationDelay: '0.2s' }}>🎁</span>
          </div>
          <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-2 md:mb-3">
            {t('title')}
          </h1>
          <p className="text-base md:text-lg text-gray-600 max-w-2xl mx-auto px-4">
            {t('subtitle')}
          </p>
        </div>

        <div className="flex justify-center gap-4 mb-8">
          <button
            onClick={() => setIsComingSoonModalOpen(false)}
            className={`px-6 py-3 rounded-xl transition-colors font-medium border-2 flex items-center gap-2 ${
              !isComingSoonModalOpen
                ? 'bg-primary text-white border-primary'
                : 'bg-white text-gray-700 border-gray-200 hover:bg-gray-50'
            }`}
          >
            🎲 {t('modes.open')}
          </button>
          <button
            onClick={() => setIsComingSoonModalOpen(true)}
            className="px-6 py-3 rounded-xl transition-colors font-medium border-2 bg-white text-gray-700 border-gray-200 hover:bg-gray-50 flex items-center gap-2"
          >
            🎅 {t('modes.secretSanta')}
          </button>
        </div>

        <div className="grid lg:grid-cols-[2fr,1fr] gap-6 md:gap-8 max-w-6xl mx-auto">
          <div className="space-y-6 md:space-y-8">
            <div className="bg-white p-4 md:p-8 rounded-2xl border-2 border-gray-100 shadow-sm">
              <RuleSelector
                selectedRule={selectedRule}
                onRuleSelect={handleRuleSelect}
                t={t}
              />
            </div>

            <div className="bg-white p-4 md:p-8 rounded-2xl border-2 border-gray-100 shadow-sm">
              <ParticipantForm
                name={name}
                setName={setName}
                onAdd={handleAddParticipant}
                onBulkAdd={handleBulkAddParticipants}
                t={t}
              />
              <ParticipantList
                participants={participants}
                onRemove={handleRemoveParticipant}
                t={t}
              />

              {participants.length < 3 && participants.length > 0 && (
                <div className="text-center text-gray-500 mt-6 md:mt-8 p-4 bg-primary-light rounded-xl border-2 border-primary/10">
                  <p>
                    {t('minParticipants.base')}
                    {t(
                      participants.length === 1
                        ? 'minParticipants.needTwo'
                        : 'minParticipants.needOne'
                    )}
                  </p>
                </div>
              )}

              {selectedRule === 'couples' && participants.length >= 2 && (
                <div className="mt-6 pt-6 border-t-2 border-gray-100">
                  <CouplesEditor
                    couples={couples}
                    participants={participants}
                    onCouplesChange={setCouples}
                    t={t}
                  />
                </div>
              )}
            </div>

            {participants.length >= 3 && (
              <div className="bg-white p-4 md:p-8 rounded-2xl border-2 border-gray-100 shadow-sm">
                <div className="flex flex-col sm:flex-row gap-3">
                  <button
                    onClick={handleDraw}
                    className="flex-1 px-4 md:px-6 py-3 md:py-4 bg-primary text-white rounded-xl hover:bg-primary-dark transition-colors font-medium border-2 border-primary flex items-center justify-center gap-2 group"
                  >
                    <span className="text-xl group-hover:rotate-12 transition-transform">🎁</span>
                    {t(isDrawComplete ? 'drawButtons.new' : 'drawButtons.start')}
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="lg:sticky lg:top-8 h-fit animate-slide-up">
            {isDrawComplete ? (
              <div className="bg-white p-4 md:p-8 rounded-2xl border-2 border-gray-100 shadow-sm">
                <Results matches={matches} t={t} />
              </div>
            ) : (
              <div className="bg-white p-4 md:p-8 rounded-2xl border-2 border-gray-100 shadow-sm flex flex-col items-center justify-center min-h-[200px] md:min-h-[300px] text-gray-500">
                <span className="text-4xl mb-4">🎁</span>
                {t('results.waiting')}
              </div>
            )}
          </div>
        </div>
      </main>

      <footer className="mt-auto py-6 bg-white border-t border-gray-100">
        <div className="container mx-auto px-4 flex flex-col items-center gap-4">
          <div className="flex flex-wrap justify-center gap-3">
            <a
              href="https://buymeacoffee.com/ardakaanozcan"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 px-4 py-2 bg-[#FFDD00] text-[#000000] rounded-lg hover:bg-[#FFDD00]/90 transition-colors"
            >
              <Coffee size={20} />
              {t('social.buyMeACoffee')}
            </a>
            <a
              href="https://www.linkedin.com/in/ardakaanozcan/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 px-4 py-2 bg-[#0A66C2] text-white rounded-lg hover:bg-[#0A66C2]/90 transition-colors"
            >
              <LinkedinIcon size={20} />
              {t('social.linkedin')}
            </a>
          </div>
          <div className="flex items-center gap-2 text-gray-500 text-sm">
            <Bot size={16} />
            <span>{t('footer.copyright')}</span>
          </div>
          <p className="text-primary font-medium">
            {t('footer.happyNewYear')}
          </p>
        </div>
      </footer>

      <ComingSoonModal
        isOpen={isComingSoonModalOpen}
        onClose={() => setIsComingSoonModalOpen(false)}
        t={t}
      />
    </div>
  );
}

export default App;