export type Language = 'tr' | 'en';

export const translations = {
  tr: {
    title: 'kimki.me',
    subtitle: 'Yılbaşı hediye çekilişi için kolay ve eğlenceli bir araç',
    modes: {
      open: 'Açık Çekiliş',
      secretSanta: 'Secret Santa (Çok Yakında)'
    },
    participantForm: {
      placeholder: 'Katılımcı adı',
      addButton: 'Ekle',
      noParticipants: 'Henüz katılımcı eklenmedi',
      participantsTitle: 'Katılımcılar',
      bulkAddHint: 'İpucu: Birden fazla katılımcıyı virgülle ayırarak ekleyebilirsiniz (örn: Ahmet, Mehmet, Ayşe)'
    },
    couples: {
      title: 'Çiftleri Belirle',
      description: 'Birbirine hediye alamayacak çiftleri seç',
      selectFirst: 'İlk kişiyi seç',
      selectSecond: 'İkinci kişiyi seç',
      addButton: 'Çifti Ekle',
      noCouples: 'Henüz çift eklenmedi'
    },
    comingSoon: {
      title: 'Çok Yakında!',
      description: 'Secret Santa özelliği çok yakında geliyor. Bu özellik ile hediye çekilişlerinizi gizli ve özel bir şekilde yapabileceksiniz.',
      features: 'Gelecek Özellikler',
      feature1: 'Otomatik email bildirimleri',
      feature2: 'Kişiye özel eşleşme sonuçları',
      feature3: 'Tamamen gizli çekiliş sistemi'
    },
    social: {
      buyMeACoffee: 'Bana bir kahve ısmarla',
      linkedin: 'LinkedIn\'de bağlanalım'
    },
    footer: {
      copyright: '© 2024 Arda Kaan Özcan',
      happyNewYear: 'İyi Yıllar! 🎄✨'
    },
    minParticipants: {
      base: 'Çekiliş için en az 3 kişi gerekli.',
      needOne: 'Bir kişi daha ekleyin!',
      needTwo: 'İki kişi daha ekleyin!'
    },
    rules: {
      title: 'Çekiliş Kuralı',
      description: 'Nasıl bir çekiliş yapmak istediğinizi seçin',
      normal: {
        name: 'Klasik Çekiliş',
        description: 'Herkes rastgele birine hediye alır'
      },
      couples: {
        name: 'Çiftler Hariç',
        description: 'Çiftler birbirlerine hediye alamazlar'
      },
      chain: {
        name: 'Hediye Zinciri',
        description: 'Herkes sırayla bir sonraki kişiye hediye alır'
      },
      groups: {
        name: 'Grup Çekilişi',
        description: 'Katılımcılar gruplara ayrılır ve gruplar arası çekiliş yapılır'
      },
      mutual: {
        name: 'Karşılıklı Hediyeleşme',
        description: 'İkili gruplar oluşturulur ve çiftler birbirlerine hediye alır'
      }
    },
    drawButtons: {
      start: 'Çekilişi Başlat',
      new: 'Yeni Çekiliş',
      redraw: 'Tekrar Çek'
    },
    results: {
      title: 'Çekiliş Sonuçları',
      givesTo: 'hediye alacak',
      waiting: 'Çekiliş sonuçları burada görünecek'
    },
    about: {
      title: 'kimki.me nedir?',
      description: 'kimki.me, yılbaşı ve özel günlerde hediye çekilişi organizasyonlarınızı kolayca yapmanızı sağlayan ücretsiz bir araçtır. Katılımcıları ekleyip, çiftleri belirleyebilir ve farklı çekiliş kuralları ile eğlenceli bir hediye organizasyonu düzenleyebilirsiniz. Çekiliş sonuçları anında oluşturulur ve herkes kime hediye alacağını kolayca öğrenebilir.',
      features: [
        'Sınırsız katılımcı ekleme',
        'Çiftler için özel kurallar',
        'Farklı çekiliş modları',
        'Anında sonuç görüntüleme',
        'Kullanımı kolay arayüz'
      ]
    }
  },
  en: {
    title: 'kimki.me',
    subtitle: 'An easy and fun tool for holiday gift exchange',
    modes: {
      open: 'Open Draw',
      secretSanta: 'Secret Santa (Coming Soon)'
    },
    participantForm: {
      placeholder: 'Participant name',
      addButton: 'Add',
      noParticipants: 'No participants added yet',
      participantsTitle: 'Participants',
      bulkAddHint: 'Tip: You can add multiple participants by separating them with commas (e.g., John, Jane, Mike)'
    },
    couples: {
      title: 'Define Couples',
      description: 'Select couples who cannot gift each other',
      selectFirst: 'Select first person',
      selectSecond: 'Select second person',
      addButton: 'Add Couple',
      noCouples: 'No couples added yet'
    },
    comingSoon: {
      title: 'Coming Soon!',
      description: 'Secret Santa feature is coming soon. With this feature, you\'ll be able to conduct your gift exchanges in a private and secret way.',
      features: 'Upcoming Features',
      feature1: 'Automatic email notifications',
      feature2: 'Personal matching results',
      feature3: 'Completely private draw system'
    },
    social: {
      buyMeACoffee: 'Buy me a coffee',
      linkedin: 'Connect on LinkedIn'
    },
    footer: {
      copyright: '© 2024 Arda Kaan Özcan',
      happyNewYear: 'Happy New Year! 🎄✨'
    },
    minParticipants: {
      base: 'At least 3 people are needed for the draw.',
      needOne: 'Add one more person!',
      needTwo: 'Add two more people!'
    },
    rules: {
      title: 'Draw Rules',
      description: 'Choose how you want to conduct the draw',
      normal: {
        name: 'Classic Draw',
        description: 'Everyone gets a random person to gift'
      },
      couples: {
        name: 'Except Couples',
        description: 'Couples cannot gift each other'
      },
      chain: {
        name: 'Gift Chain',
        description: 'Everyone gifts to the next person in line'
      },
      groups: {
        name: 'Group Draw',
        description: 'Participants are divided into groups and draw between groups'
      },
      mutual: {
        name: 'Mutual Exchange',
        description: 'Create pairs where both people gift to each other'
      }
    },
    about: {
      title: 'What is kimki.me?',
      description: 'kimki.me is a free tool that helps you easily organize gift exchanges for New Year\'s and special occasions. You can add participants, define couples, and arrange a fun gift exchange with different drawing rules. Draw results are generated instantly, and everyone can easily learn who they will be gifting to.',
      features: [
        'Unlimited participants',
        'Special rules for couples',
        'Different drawing modes',
        'Instant result viewing',
        'User-friendly interface'
      ]
    }
  }
};