export type RuleType = 'normal' | 'couples' | 'chain' | 'groups' | 'mutual';

export type Rule = {
  type: RuleType;
  icon: string;
};

export type Couple = {
  person1: string;
  person2: string;
};

export type Participant = {
  name: string;
  email?: string;
};

export const RULES: Rule[] = [
  {
    type: 'normal',
    icon: '🎲',
  },
  {
    type: 'couples',
    icon: '💑',
  },
  {
    type: 'chain',
    icon: '⛓️',
  },
  {
    type: 'groups',
    icon: '👥',
  },
  {
    type: 'mutual',
    icon: '🤝',
  }
];