import React from 'react';
import { X, Gift } from 'lucide-react';

interface ComingSoonModalProps {
  isOpen: boolean;
  onClose: () => void;
  t: (key: string) => string;
}

export function ComingSoonModal({ isOpen, onClose, t }: ComingSoonModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50 animate-fade-in">
      <div className="bg-white rounded-2xl max-w-md w-full p-6 relative animate-slide-up">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors"
        >
          <X size={24} />
        </button>

        <div className="text-center mb-6">
          <div className="flex justify-center mb-4">
            <div className="bg-primary/10 p-3 rounded-full">
              <Gift size={32} className="text-primary" />
            </div>
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-2">
            {t('comingSoon.title')}
          </h2>
          <p className="text-gray-600">
            {t('comingSoon.description')}
          </p>
        </div>

        <div className="bg-primary/5 rounded-xl p-4">
          <h3 className="font-semibold text-gray-900 mb-2">
            {t('comingSoon.features')}
          </h3>
          <ul className="space-y-2 text-gray-600">
            <li className="flex items-center gap-2">
              <span className="text-primary">•</span>
              {t('comingSoon.feature1')}
            </li>
            <li className="flex items-center gap-2">
              <span className="text-primary">•</span>
              {t('comingSoon.feature2')}
            </li>
            <li className="flex items-center gap-2">
              <span className="text-primary">•</span>
              {t('comingSoon.feature3')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
} 